import React from "react";
import { Container } from "components/containers/container/container";
import { MEDIA_SOURCE_CONSTANTS } from "utilities/constants/media-source-constants";
import { RainbowText } from "components/typography/rainbow-text/rainbow-text";
import { Video } from "components/videos/video/video";
import "./no-shifts-content.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface NoShiftsContentProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const BOTTOM_MESSAGE: string = "You never have to work another shift there again!";
const CSS_CLASS_NAME: string = "no-shifts-content";
const TOP_MESSAGE: string = "Congrats! I'm so proud of you!";
const VIDEO_HOVER_TEXT: string = "Us? 😳";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NoShiftsContent: React.FC<NoShiftsContentProps> = (): JSX.Element => {

    return (
        <>
            <Container cssClassName={`${CSS_CLASS_NAME}__message`}>
                <RainbowText text={TOP_MESSAGE} />
                <RainbowText text={BOTTOM_MESSAGE} />
            </Container>
            <Video hoverText={VIDEO_HOVER_TEXT} src={MEDIA_SOURCE_CONSTANTS.EASTER_EGG} />
        </>
    );
}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NoShiftsContent };

// #endregion Exports
