// -------------------------------------------------------------------------------------------------
// #region Interface
// -------------------------------------------------------------------------------------------------

interface NumberUtility {
    isInteger(value: number): boolean;
}

// #endregion Interface

// -------------------------------------------------------------------------------------------------
// #region Utility Functions
// -------------------------------------------------------------------------------------------------

const isInteger = (value: number): boolean => Math.abs(value) !== Infinity && Math.floor(value) === value;

// #endregion Utility Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const NumberUtils: NumberUtility = {
    isInteger: isInteger,
};

export { NumberUtils };

// #endregion Exports
