import React from "react";
import { DEFAULT_TEXT_COLOR, TextColor, TextColorToClassMap } from "utilities/enums/styles/text-color";
import { useClassNames } from "utilities/hooks/use-class-names";
import "./paragraph.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ParagraphProps {
    color?: TextColor;
    cssClassName?: string;
    style?: ParagraphStyle;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum ParagraphStyle {
    Decorative = "-decorative",
    Default = "",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "paragraph";
const DEFAULT_STYLE: ParagraphStyle = ParagraphStyle.Default;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Paragraph: React.FC<React.PropsWithChildren<ParagraphProps>> = (
    props: React.PropsWithChildren<ParagraphProps>,
): JSX.Element => {
    const className: string = useClassNames(
        CSS_CLASS_NAME,
        props.style ?? DEFAULT_STYLE,
        TextColorToClassMap[props.color ?? DEFAULT_TEXT_COLOR],
        props.cssClassName,
    );

    return (
        <p className={className}>
            {props.children}
        </p>
    )
}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export {
    Paragraph,
    Paragraph as P,
    ParagraphStyle,
};

// #endregion Exports