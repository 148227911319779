import React, { useMemo } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MatchingValueWrapperProps<T> {
    currentValue: T;
    targetValue: T;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MatchingValueWrapper = <T,>(
    props: React.PropsWithChildren<MatchingValueWrapperProps<T>>
): JSX.Element => {
    const doesNotMatch: boolean = useMemo(
        (): boolean => props.currentValue !== props.targetValue,
        [props.currentValue, props.targetValue]
    );

    if (doesNotMatch) {
        return <></>;
    }

    return (
        <>
            {props.children}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { MatchingValueWrapper };

// #endregion Exports
