import React, { useMemo } from "react";
import { ItemCounterProps } from "components/counters/item-counter/item-counter";
import { P, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { StringUtils } from "utilities/string-utility";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface HasItemsRemainingCounterContentProps extends ItemCounterProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const HasItemsRemainingCounterContent: React.FC<HasItemsRemainingCounterContentProps> = (
    props: HasItemsRemainingCounterContentProps,
): JSX.Element => {
    const pluralizedItemLabel: string = useMemo(
        (): string => StringUtils.pluralize(props.itemLabel, props.itemsRemaining),
        [props.itemLabel, props.itemsRemaining],
    );

    return (
        <>
            <P color={props.color} style={ParagraphStyle.Decorative}>
                {props.itemsRemaining}
            </P>
            <P color={props.color}>{pluralizedItemLabel} Remaining</P>
        </>
    )
}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { HasItemsRemainingCounterContent };

// #endregion Exports
