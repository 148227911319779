import React from "react";
import "./video.scss";

// -------------------------------------------------------------------------------------------------
// #region Interface
// -------------------------------------------------------------------------------------------------

export interface VideoProps {
    hoverText?: string;
    src: string;
}

// #endregion Interface

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "video";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Video: React.FC<VideoProps> = (props: VideoProps): JSX.Element => {
    return (
        <video
            autoPlay={false}
            className={CSS_CLASS_NAME}
            controls={true}
            title={props.hoverText}
            src={props.src} />
    )}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Video };

// #endregion Exports
