import { useMemo } from "react";
import { ShiftCountdownUtils } from "utilities/shift-countdown-utility";

// -------------------------------------------------------------------------------------------------
// #region Interface
// -------------------------------------------------------------------------------------------------

interface UseShiftsRemainingHook {
    daysRemaining: number;
    lateShiftsRemaining: number;
    morningShiftsRemaining: number;
    nightShiftsRemaining: number;
    shiftsRemaining: number;
}

// #endregion Interface

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useShiftsRemaining = (): UseShiftsRemainingHook => {
    const daysRemaining: number = useMemo((): number => ShiftCountdownUtils.countRemainingDays(), []);
    const lateShiftsRemaining: number = useMemo((): number => ShiftCountdownUtils.countRemainingLateShifts(), []);
    const morningShiftsRemaining: number = useMemo((): number => ShiftCountdownUtils.countRemainingMorningShifts(), []);
    const nightShiftsRemaining: number = useMemo((): number => ShiftCountdownUtils.countRemainingNightShifts(), []);
    const shiftsRemaining: number = useMemo((): number => ShiftCountdownUtils.countRemainingShifts(), []);

    return {
        daysRemaining: daysRemaining,
        lateShiftsRemaining: lateShiftsRemaining,
        morningShiftsRemaining: morningShiftsRemaining,
        nightShiftsRemaining: nightShiftsRemaining,
        shiftsRemaining: shiftsRemaining,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useShiftsRemaining };

// #endregion Exports
