import React, { useMemo } from "react";
import { Container } from "components/containers/container/container";
import { HasItemsRemainingCounterContent } from "components/counters/item-counter/has-items-remaining-counter-content/has-items-remaining-counter-content";
import { NoItemsRemainingCounterContent } from "components/counters/item-counter/no-items-remaining-counter-content/no-items-remaining-counter-content";
import { TextColor } from "utilities/enums/styles/text-color";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ItemCounterProps {
    color?: TextColor;
    cssClassName?: string;
    itemLabel: string;
    itemsRemaining: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ItemCounter: React.FC<ItemCounterProps> = (props: ItemCounterProps): JSX.Element => {
    const itemCounterContent: JSX.Element = useMemo(
        (): JSX.Element => props.itemsRemaining === 0
            ? <NoItemsRemainingCounterContent {...props} itemsRemaining={0} />
            : <HasItemsRemainingCounterContent {...props} />,
        [props],
    );

    return (
        <Container cssClassName={props.cssClassName}>
            { itemCounterContent }
        </Container>
    )
}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

export { ItemCounter };

// #endregion Constants