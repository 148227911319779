import { ShiftsByDate } from "utilities/constants/shifts-by-date";
import { ShiftType } from "utilities/enums/shifts/ShiftType";
import { ShiftByDate } from "utilities/interfaces/shift-by-date";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ShiftCountdownUtility {
    countRemainingDays: () => number;
    countRemainingLateShifts: () => number;
    countRemainingMorningShifts: () => number;
    countRemainingNightShifts: () => number;
    countRemainingShifts: () => number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const SHIFT_START_TIME_BY_TYPE_MAP: Record<ShiftType, number> = {
    [ShiftType.Late]: 14,
    [ShiftType.Morning]: 6,
    [ShiftType.Night]: 22,
    [ShiftType.None]: 0,
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ACTUAL_TIME: Date = new Date();
const MINUTES_IN_AN_HOUR: number = 60;
const LOCAL_TIME_ZONE_SHIFT_FROM_UTC_IN_HOURS = ACTUAL_TIME.getTimezoneOffset() / MINUTES_IN_AN_HOUR;
const STANDARD_SHIFT_DURATION: number = 8;
const SUNDAY: number = 0;
const SUNDAY_SHIFT_DURATION: number = 12;
const TIME_ZONE_SHIFT_FROM_UTC_IN_HOURS: number = 2;
const TIME_ZONE_SHIFT_IN_HOURS: number = LOCAL_TIME_ZONE_SHIFT_FROM_UTC_IN_HOURS + TIME_ZONE_SHIFT_FROM_UTC_IN_HOURS;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region UtilityFunctions
// -------------------------------------------------------------------------------------------------

const countRemainingDays = (): number => _getRemainingDays().length;

const countRemainingLateShifts = (): number => _countRemainingShiftsByType(ShiftType.Late);

const countRemainingMorningShifts = (): number => _countRemainingShiftsByType(ShiftType.Morning);

const countRemainingNightShifts = (): number => _countRemainingShiftsByType(ShiftType.Night);

const countRemainingShifts = (): number => _countRemainingShiftsAfterFilters(_hasShift);

// #endregion UtilityFunctions

// -------------------------------------------------------------------------------------------------
// #region Private Helpers
// -------------------------------------------------------------------------------------------------

const _adjustDateTimeToLocalTimeZone = (date: Date): Date  => {
    // console.log(`Pre instantiation: ${date.getHours()}`);
    const adjustedDate = new Date(date);
    // console.log(`Post instantiation: ${date.getHours()} vs ${adjustedDate.getHours()}`);

    adjustedDate.setHours(date.getHours() + TIME_ZONE_SHIFT_IN_HOURS);
    // console.log(`Post set: ${date.getHours()} vs ${adjustedDate.getHours()}`);

    return adjustedDate;
}

const _applyFiltersToRemainingDays = (
    ...filters: ((shiftByDate: ShiftByDate) => boolean)[]
): ShiftByDate[] => filters.reduce(_filterShiftsReducer, _getIncompleteShifts());

const _countRemainingShiftsAfterFilters = (
    ...filters: ((shiftByDate: ShiftByDate) => boolean)[]
): number => _applyFiltersToRemainingDays(...filters).length;

const _calculateShiftEndHour = (shiftByDate: ShiftByDate): number =>
    _getShiftStartTime(shiftByDate.shiftType) + _getShiftDuration(shiftByDate.date);

const _calculateShiftEndTime = (shiftByDate: ShiftByDate): number => new Date(
        shiftByDate.date.getFullYear(),
        shiftByDate.date.getMonth(),
        shiftByDate.date.getDate(),
        _calculateShiftEndHour(shiftByDate),
    ).getTime();

const _countRemainingShiftsByType = (shiftType: ShiftType): number => _countRemainingShiftsAfterFilters(_filterByShiftTypeFactory(shiftType));

const _getCurrentDateAtMidnightAdjustedToLocalTimeZone = (): number => {
    const localToday: Date = new Date(_getCurrentTimeAdjustedToLocalTimeZone());

    return new Date(localToday.getFullYear(), localToday.getMonth(), localToday.getDate()).getTime();
}

const _getCurrentTimeAdjustedToLocalTimeZone = (): number => _adjustDateTimeToLocalTimeZone(new Date()).getTime();

const _getIncompleteShifts = (): ShiftByDate[] => ShiftsByDate.filter(_shiftIsInTheFuture);

const _getRemainingDays = (): ShiftByDate[] => ShiftsByDate.filter(_shiftIsOnDateThatHasNotEnded);

const _getShiftDuration = (shiftDate: Date): number => shiftDate.getDay() === SUNDAY ? SUNDAY_SHIFT_DURATION : STANDARD_SHIFT_DURATION;

const _getShiftStartTime = (shiftType: ShiftType): number => SHIFT_START_TIME_BY_TYPE_MAP[shiftType];

const _filterByShiftTypeFactory = (shiftType: ShiftType): (shiftByDate: ShiftByDate) => boolean =>
    (shiftByDate: ShiftByDate): boolean => _shiftIsOfType(shiftByDate, shiftType);

const _filterShiftsReducer = (
    remainingShiftsByDate: ShiftByDate[],
    filter: (shiftByDate: ShiftByDate) => boolean,
): ShiftByDate[] => remainingShiftsByDate.filter(filter);

const _hasShift = (shiftByDate: ShiftByDate): boolean => shiftByDate.shiftType !== ShiftType.None;

const _shiftIsInTheFuture = (shiftByDate: ShiftByDate): boolean => _calculateShiftEndTime(shiftByDate) > _getCurrentTimeAdjustedToLocalTimeZone();

const _shiftIsOnDateThatHasNotEnded = (shiftByDate: ShiftByDate): boolean => shiftByDate.date.getTime() >= _getCurrentDateAtMidnightAdjustedToLocalTimeZone();

const _shiftIsOfType = (shiftByDate: ShiftByDate, shiftType: ShiftType): boolean => shiftByDate.shiftType === shiftType;

// #endregion Private Helpers

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const ShiftCountdownUtils: ShiftCountdownUtility = {
    countRemainingDays: countRemainingDays,
    countRemainingLateShifts: countRemainingLateShifts,
    countRemainingMorningShifts: countRemainingMorningShifts,
    countRemainingNightShifts: countRemainingNightShifts,
    countRemainingShifts: countRemainingShifts,
};

export { ShiftCountdownUtils };

// #endregion Exports
