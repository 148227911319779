import { useMemo } from "react";
import { AlignItems } from "components/containers/flex/enums/align-items";
import { FlexDirection } from "components/containers/flex/enums/flex-direction";
import { FlexProperties } from "components/containers/flex/interfaces/flex-properties";
import { JustifyContent } from "components/containers/flex/enums/justify-content";
import { FlexWrap } from "components/containers/flex/enums/flex-wrap";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ALIGN_ITEMS_PREFIX: string = "-align-items";
const BASE_CLASS_NAME: string = "flex";
const FLEX_DIRECTION_PREFIX: string = "-flex-direction";
const FLEX_WRAP_PREFIX: string = "-flex-wrap";
const JUSTIFY_CONTENT_PREFIX: string = "-justify-content";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Default Parameter Values
// -------------------------------------------------------------------------------------------------

const DEFAULT_FLEX_PROPERTIES: FlexProperties = {
    alignItems: AlignItems.Center,
    flexDirection: FlexDirection.Row,
    flexWrap: FlexWrap.NoWrap,
    justifyContent: JustifyContent.Center,
};

// #endregion Default Parameter Values

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const ALIGN_ITEMS_CLASSES: Record<AlignItems, string> = {
    [AlignItems.Center]: `${ALIGN_ITEMS_PREFIX}__center`,
};
const FLEX_DIRECTION_CLASSES: Record<FlexDirection, string> = {
    [FlexDirection.Column]: `${FLEX_DIRECTION_PREFIX}__column`,
    [FlexDirection.Row]: `${FLEX_DIRECTION_PREFIX}__row`,
};
const FLEX_WRAP_CLASSES: Record<FlexWrap, string> = {
    [FlexWrap.NoWrap]: `${FLEX_WRAP_PREFIX}__no-wrap`,
    [FlexWrap.Wrap]: `${FLEX_WRAP_PREFIX}__wrap`,
};
const JUSTIFY_CONTENT_CLASSES: Record<JustifyContent, string> = {
    [JustifyContent.Center]: `${JUSTIFY_CONTENT_PREFIX}__center`,
    [JustifyContent.End]: `${JUSTIFY_CONTENT_PREFIX}__end`,
    [JustifyContent.Start]: `${JUSTIFY_CONTENT_PREFIX}__start`,
};

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useFlexStyles = (incomingFlexProperties?: Partial<FlexProperties>): string => {
    const flexProperties: FlexProperties = useMemo((): FlexProperties => ({
        ...DEFAULT_FLEX_PROPERTIES,
        ...(incomingFlexProperties ?? {})
    }), [incomingFlexProperties]);

    const cssClassNames: string[] = useMemo((): string[] => ([
        BASE_CLASS_NAME,
        ALIGN_ITEMS_CLASSES[flexProperties.alignItems],
        FLEX_DIRECTION_CLASSES[flexProperties.flexDirection],
        FLEX_WRAP_CLASSES[flexProperties.flexWrap],
        JUSTIFY_CONTENT_CLASSES[flexProperties.justifyContent],
    ]), [
        flexProperties.alignItems,
        flexProperties.flexDirection,
        flexProperties.flexWrap,
        flexProperties.justifyContent
    ]);

    const cssClassName: string = useMemo(
        (): string => cssClassNames.join(" ").trim(),
        [cssClassNames],
    );

    return cssClassName;
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useFlexStyles }

// #endregion Exports
