import React from "react";
import { ItemCounter } from "components/counters/item-counter/item-counter";
import { MatchingValueWrapper } from "components/wrappers/matching-value-wrapper/matching-value-wrapper";
import { MEDIA_SOURCE_CONSTANTS } from "utilities/constants/media-source-constants";
import { ShiftByTypeCounter } from "components/counters/shift-by-type-counter/shift-by-type-counter";
import { useShiftsRemaining } from "utilities/hooks/use-shifts-remaining";
import { Video } from "components/videos/video/video";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface HasShiftsContentProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const TOMORRY_VIDEO_SHIFT_TARGET: number = 1;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const HasShiftsContent: React.FC<HasShiftsContentProps> = (): JSX.Element => {
    const {
        daysRemaining,
        lateShiftsRemaining,
        morningShiftsRemaining,
        nightShiftsRemaining,
        shiftsRemaining,
    } = useShiftsRemaining();

    return (
        <>
            <MatchingValueWrapper
                currentValue={shiftsRemaining}
                targetValue={TOMORRY_VIDEO_SHIFT_TARGET}>
                <Video src={MEDIA_SOURCE_CONSTANTS.TOMORRY_VIDEO} />
            </MatchingValueWrapper>
            <ItemCounter itemLabel="Shift" itemsRemaining={shiftsRemaining} />
            <ShiftByTypeCounter
                lateShiftsRemaining={lateShiftsRemaining}
                morningShiftsRemaining={morningShiftsRemaining}
                nightShiftsRemaining={nightShiftsRemaining} />
            <ItemCounter itemLabel="Day" itemsRemaining={daysRemaining} />
        </>
    );
}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { HasShiftsContent };

// #endregion Exports
