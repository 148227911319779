import React from "react";
import { useClassNames } from "utilities/hooks/use-class-names";
import "./container.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ContainerProps {
    cssClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "container";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Container: React.FC<React.PropsWithChildren<ContainerProps>> = (
    props: React.PropsWithChildren<ContainerProps>,
): JSX.Element => {
    const cssClassName: string = useClassNames(
        CSS_CLASS_NAME,
        props.cssClassName,
    );

    return (
        <div className={cssClassName}>
            {props.children}
        </div>
    )
}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Container };

// #endregion Exports
