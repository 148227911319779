import { StringUtils } from "utilities/string-utility"

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CLASS_SEPARATOR: string = " ";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useClassNames = (...classNames: (string | undefined)[]): string => classNames
    .filter(StringUtils.hasValue)
    .join(CLASS_SEPARATOR)
    .trim();

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useClassNames };

// #endregion Exports
