import React from "react";
import { HomePage } from "components/pages/home-page/home-page";
import "assets/styles/app.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AppProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const App: React.FC<AppProps> = (): JSX.Element => <HomePage />;

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { App };

// #endregion Exports