// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MediaSourceConstants {
    EASTER_EGG: string;
    TOMORRY_VIDEO: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Helper Constants
// -------------------------------------------------------------------------------------------------

const MEDIA_DIRECTORY: string = "media";
const VIDEOS_DIRECTORY: string = `${MEDIA_DIRECTORY}/videos`;

// #endregion Helper Constants

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const MEDIA_SOURCE_CONSTANTS: MediaSourceConstants = {
    EASTER_EGG: `${VIDEOS_DIRECTORY}/easter-egg.mov`,
    TOMORRY_VIDEO: `${VIDEOS_DIRECTORY}/tomorry-video.mov`,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { MEDIA_SOURCE_CONSTANTS };

// #endregion Exports
