import React from "react";
import { FlexDirection } from "components/containers/flex/enums/flex-direction";
import { Flex } from "components/containers/flex/flex";
import { JustifyContent } from "components/containers/flex/enums/justify-content";
import { useClassNames } from "utilities/hooks/use-class-names";
import "./app-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AppLayoutProps {
    cssClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "app-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AppLayout: React.FC<React.PropsWithChildren<AppLayoutProps>> = (
    props: React.PropsWithChildren<AppLayoutProps>,
): JSX.Element => {
    const cssClassName: string = useClassNames(
        CSS_CLASS_NAME,
        props.cssClassName,
    );

    return (
        <Flex
            cssClassName={cssClassName}
            flexDirection={FlexDirection.Column}
            element="main"
            justifyContent={JustifyContent.Start}>
            {props.children}
        </Flex>
    )
}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AppLayout };

// #endregion Exports
