// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface DateConstants {
    AUGUST: number;
    JULY: number;
    SEPTEMBER: number;
    YEAR: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const DATE_CONSTANTS: DateConstants = {
    AUGUST: 7,
    JULY: 6,
    SEPTEMBER: 8,
    YEAR: 2023,
}

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { DATE_CONSTANTS };

// #endregion Exports
