// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum JustifyContent {
    Center,
    End,
    Start,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { JustifyContent };

// #endregion Exports
