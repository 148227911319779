import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "app";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ROOT_NAME: string= "root";
const ROOT_ELEMENT: HTMLElement = document.getElementById(ROOT_NAME) as HTMLElement;
const ROOT: ReactDOM.Root = ReactDOM.createRoot(ROOT_ELEMENT);

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Render
// -------------------------------------------------------------------------------------------------

ROOT.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// #endregion Render
