// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum TextColor {
    Blue,
    Green,
    White,
    Yellow,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const DEFAULT_TEXT_COLOR: TextColor = TextColor.White;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const TextColorToClassMap: Record<TextColor, string> = {
    [TextColor.Blue]: "-blue",
    [TextColor.Green]: "-green",
    [TextColor.White]: "",
    [TextColor.Yellow]: "-yellow",
}

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export {
    DEFAULT_TEXT_COLOR,
    TextColor,
    TextColorToClassMap,
};

// #endregion Exports
