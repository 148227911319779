import React from "react";
import { AppLayout } from "components/layouts/app-layout/app-layout";
import { HasShiftsContent } from "components/shifts/has-shifts-content/has-shifts-content";
import { MatchingValueWrapper } from "components/wrappers/matching-value-wrapper/matching-value-wrapper";
import { NonMatchingValueWrapper } from "components/wrappers/non-matching-value-wrapper/non-matching-value-wrapper";
import { NoShiftsContent } from "components/shifts/no-shifts-content/no-shifts-content";
import { useShiftsRemaining } from "utilities/hooks/use-shifts-remaining";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface HomePageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const NO_REMAINING_SHIFTS: number = 0;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const HomePage: React.FC<HomePageProps> = (): JSX.Element => {
    const { shiftsRemaining } = useShiftsRemaining();

    return (
        <AppLayout>
            <NonMatchingValueWrapper
                currentValue={shiftsRemaining}
                excludeValue={NO_REMAINING_SHIFTS}>
                <HasShiftsContent />
            </NonMatchingValueWrapper>
            <MatchingValueWrapper
                currentValue={shiftsRemaining}
                targetValue={NO_REMAINING_SHIFTS}>
                <NoShiftsContent />
            </MatchingValueWrapper>
        </AppLayout>
    )
}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { HomePage };

// #endregion Exports
