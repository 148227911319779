import React, { useCallback, useMemo } from "react";
import { P, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { TextColorToClassMap } from "utilities/enums/styles/text-color";
import "./rainbow-text.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface RainbowTextProps {
    text: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "rainbow-text";
const INDEX_OFFSET: number = 1;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RainbowText: React.FC<RainbowTextProps> = (props: RainbowTextProps): JSX.Element => {
    const availableColors: string[] = useMemo((): string[] => Object.values(TextColorToClassMap), []);
    const letters: string[] = useMemo((): string[] => props.text?.split("") ?? "", [props.text]);

    const getColorClass = useCallback(
        (index: number): string => availableColors[(index + INDEX_OFFSET) % availableColors.length],
        [availableColors]
    );

    const letterToSpan = useCallback(
        (letter: string, index: number): JSX.Element => {
            const colorClass: string = getColorClass(index);
            const key: string = `${letter}-${index}${colorClass}`;
            const className: string = `${CSS_CLASS_NAME}__letter ${colorClass}`

            return <span className={className} key={key}>{letter}</span>;
        },
        [getColorClass]
    );

    const rainbowLetters: JSX.Element[] = useMemo(
        (): JSX.Element[] => letters.map(letterToSpan),
        [letterToSpan, letters]
    );

    return <P cssClassName={CSS_CLASS_NAME} style={ParagraphStyle.Decorative}>{rainbowLetters}</P>;
}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { RainbowText };

// #endregion Exports
