import React, { useMemo } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface NonMatchingValueWrapperProps<T> {
    currentValue: T;
    excludeValue: T;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NonMatchingValueWrapper = <T,>(
    props: React.PropsWithChildren<NonMatchingValueWrapperProps<T>>
): JSX.Element => {
    const matches: boolean = useMemo(
        (): boolean => props.currentValue === props.excludeValue,
        [props.currentValue, props.excludeValue]
    );

    if (matches) {
        return <></>;
    }

    return (
        <>
            {props.children}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NonMatchingValueWrapper };

// #endregion Exports
