// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

import { NumberUtils } from "utilities/number-utility";

interface StringUtility {
    hasValue(str?: string | null): str is string;
    isEmpty(str?: string | null): str is "" | undefined;
    pluralize(str: string, amount: number): string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const PLURALIZE_INVALID_STRING_ERROR_MESSAGE: string = "[StringUtils.pluralize] Argument Exception: String must have non-whitespace content";
const PLURALIZE_INVALID_AMOUNT_ERROR_MESSAGE: string = "[StringUtils.pluralize] Argument Exception: Amount must be a non-negative integer";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Utility Functions
// -------------------------------------------------------------------------------------------------

const hasValue = (str?: string | null): str is string => !isEmpty(str);

const isEmpty = (str?: string | null): str is "" | undefined => (str == null || str.length === 0);

const pluralize = (
    str: string,
    amount: number,
    suffix: string = "s",
): string => {
    if (isEmpty(str.trim())) {
        throw new Error(PLURALIZE_INVALID_STRING_ERROR_MESSAGE);
    }

    if (!NumberUtils.isInteger(amount) || amount < 0) {
        throw new Error(PLURALIZE_INVALID_AMOUNT_ERROR_MESSAGE);
    }

    const pluralizer: string = amount === 1 ? "" : suffix;

    return `${str}${pluralizer}`;
}

// #endregion Utility Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const StringUtils: StringUtility = {
    hasValue: hasValue,
    isEmpty: isEmpty,
    pluralize: pluralize,
};

export { StringUtils };

// #endregion Exports
