import React from "react";
import { Flex } from "components/containers/flex/flex";
import { ItemCounter } from "components/counters/item-counter/item-counter";
import { TextColor } from "utilities/enums/styles/text-color";
import "./shift-by-type-counter.scss";

// -------------------------------------------------------------------------------------------------
// #region Interface
// -------------------------------------------------------------------------------------------------

interface ShiftByTypeCounterProps {
    lateShiftsRemaining: number;
    morningShiftsRemaining: number;
    nightShiftsRemaining: number;
}

// #endregion Interface

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "shift-by-type-counter";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ShiftByTypeCounter: React.FC<ShiftByTypeCounterProps> = (props: ShiftByTypeCounterProps): JSX.Element => {
    return (
        <Flex cssClassName={CSS_CLASS_NAME}>
            <ItemCounter
                color={TextColor.Yellow}
                cssClassName={`${CSS_CLASS_NAME}__counter`}
                itemLabel="Morning Shift"
                itemsRemaining={props.morningShiftsRemaining} />
            <ItemCounter
                color={TextColor.Green}
                cssClassName={`${CSS_CLASS_NAME}__counter`}
                itemLabel="Late Shift"
                itemsRemaining={props.lateShiftsRemaining} />
            <ItemCounter
                color={TextColor.Blue}
                cssClassName={`${CSS_CLASS_NAME}__counter`}
                itemLabel="Night Shift"
                itemsRemaining={props.nightShiftsRemaining} />
        </Flex>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ShiftByTypeCounter };

// #endregion Exports
