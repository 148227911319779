import React, { useMemo } from "react";
import { FlexProperties } from "components/containers/flex/interfaces/flex-properties";
import { useFlexStyles } from "components/containers/flex/hooks/use-flex-styles";
import { useClassNames } from "utilities/hooks/use-class-names";
import "./flex.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface FlexProps extends Partial<FlexProperties> {
    cssClassName?: string;
    element?: keyof React.ReactHTML;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const DEFAULT_ELEMENT: keyof React.ReactHTML = "div";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Flex: React.FC<React.PropsWithChildren<FlexProps>> = (
    props: React.PropsWithChildren<FlexProps>
): JSX.Element => {
    const element: keyof React.ReactHTML = useMemo(
        (): keyof React.ReactHTML => props.element ?? DEFAULT_ELEMENT,
        [props.element]
    );
    const flexClassName: string = useFlexStyles(props);
    const cssClassName: string = useClassNames(
        flexClassName,
        props.cssClassName,
    );
    const elementProps: React.HTMLAttributes<typeof element> = useMemo(
        (): React.HTMLAttributes<typeof element> => ({ className: cssClassName }),
        [cssClassName]
    );


    return React.createElement(
        element,
        elementProps,
        props.children,
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Flex };

// #endregion Exports
