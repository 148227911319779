import React, { useMemo } from "react";
import { ItemCounterProps } from "components/counters/item-counter/item-counter";
import { P } from "components/typography/paragraph/paragraph";
import { StringUtils } from "utilities/string-utility";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface NoItemsRemainingCounterContentProps extends ItemCounterProps {
    itemsRemaining: 0;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NoItemsRemainingCounterContent: React.FC<NoItemsRemainingCounterContentProps> = (
    props: NoItemsRemainingCounterContentProps,
): JSX.Element => {
    const lowercasePluralizedItemLabel: string = useMemo(
        (): string => StringUtils.pluralize(props.itemLabel, props.itemsRemaining).toLocaleLowerCase(),
        [props.itemLabel, props.itemsRemaining],
    );

    return <P color={props.color}>You don't have any more {lowercasePluralizedItemLabel}!</P>;
}

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NoItemsRemainingCounterContent };

// #endregion Exports
