import { DATE_CONSTANTS } from "utilities/constants/date-constants";
import { ShiftByDate } from "utilities/interfaces/shift-by-date";
import { ShiftType } from "utilities/enums/shifts/ShiftType";

// -------------------------------------------------------------------------------------------------
// #region Constant
// -------------------------------------------------------------------------------------------------

const ShiftsByDate: ShiftByDate[] = [
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 17), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 18), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 19), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 20), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 21), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 22), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 23), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 24), shiftType: ShiftType.Night },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 25), shiftType: ShiftType.Night },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 26), shiftType: ShiftType.Night },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 27), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 28), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 29), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 30), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.JULY, 31), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 1), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 2), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 3), shiftType: ShiftType.Late },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 4), shiftType: ShiftType.Late },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 5), shiftType: ShiftType.Late },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 6), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 7), shiftType: ShiftType.Late },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 8), shiftType: ShiftType.Late },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 9), shiftType: ShiftType.Late },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 10), shiftType: ShiftType.Night },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 11), shiftType: ShiftType.Night },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 12), shiftType: ShiftType.Night },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 13), shiftType: ShiftType.Night },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 14), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 15), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 16), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 17), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 18), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 19), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 20), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 21), shiftType: ShiftType.Night },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 22), shiftType: ShiftType.Night },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 23), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 24), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 25), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 26), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 27), shiftType: ShiftType.None },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 28), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 29), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 30), shiftType: ShiftType.Morning },
    { date: new Date(DATE_CONSTANTS.YEAR, DATE_CONSTANTS.AUGUST, 31), shiftType: ShiftType.Late },
]

// #endregion Constant

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ShiftsByDate };

// #endregion Exports